import AWN from "awesome-notifications"

document.addEventListener("DOMContentLoaded", () => {
    const downloadBtn = document.getElementById('time-entries-download')
    const $timeEntry = $("#time-entries-workorder")

    if ($timeEntry) {
        const timeEntriesContainer = document.getElementById("time-entries-card")
        const timeEntriesBudgetedContainer = document.getElementById("time-entries-budgeted")
        const timeEntriesBudgetedTotal = document.getElementById("time-entries-total-budgeted")
        const timeEntriesSpentTotal = document.getElementById("time-entries-total-spent-budgeted")
        const timeEntriesRemainingTotal = document.getElementById("time-entries-total-remaining-budgeted")
        const timeEntriesNonBudgetedContainer = document.getElementById("time-entries-non-budgeted")
        const timeEntriesNonBudgetedTotal = document.getElementById("time-entries-total-non-budgeted")

        $timeEntry.select2({})

        $timeEntry.on("change", (e) => {
            let notifier = new AWN()
            const selectedValue = $(e.target).select2("val")

            if (selectedValue !== "") {
                timeEntriesContainer.classList.add("d-none")
                timeEntriesBudgetedContainer.innerHTML = ""
                timeEntriesNonBudgetedContainer.innerHTML = ""

                const formatter = Intl.NumberFormat('en-GB', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                });

                notifier.asyncBlock(
                    axios.get('/dashboard/time-entries/' + $(e.target).select2("val")).then((resp) => {
                        let totalBudgetedTime = 0,
                            totalBudgetedSpent = 0,
                            totalBudgetedRemaining = 0,
                            totalNonBudgetedTime = 0

                        Object.keys(resp?.data).forEach((key) => {
                            if (resp.data[key].billable) {
                                const budget = parseFloat(resp.data[key].budget)
                                const spent = parseFloat(resp.data[key].spent)
                                const remaining = parseFloat(resp.data[key].remaining)
                                let rowColour = "table-success"

                                if (spent > budget) {
                                    rowColour = "table-danger"
                                }

                                const html = "<tr class=\"" + rowColour + "\"><td>" + resp.data[key].name + "</td><td>" + formatter.format(budget) + "</td><td>" + formatter.format(spent) + "</td><td>" + formatter.format(remaining) + "</td></tr>"

                                totalBudgetedTime += budget
                                totalBudgetedSpent += spent
                                totalBudgetedRemaining += remaining

                                timeEntriesBudgetedContainer.insertAdjacentHTML("beforeend", html)
                            } else {
                                const spent = parseFloat(resp.data[key].spent)
                                const html = "<tr><td>" + resp.data[key].name + "</td><td>" + formatter.format(spent) + "</td>"

                                totalNonBudgetedTime += spent

                                timeEntriesNonBudgetedContainer.insertAdjacentHTML("beforeend", html)
                            }
                        })

                        timeEntriesBudgetedTotal.innerText = formatter.format(totalBudgetedTime)
                        timeEntriesSpentTotal.innerText = formatter.format(totalBudgetedSpent)
                        timeEntriesRemainingTotal.innerText = formatter.format(totalBudgetedRemaining)
                        timeEntriesNonBudgetedTotal.innerText = formatter.format(totalNonBudgetedTime)
                        timeEntriesContainer.classList.remove("d-none")

                        notifier.success(`Time Entries Loaded`)
                    }),
                    null,
                )
            } else {
                timeEntriesContainer.classList.add("d-none")
                notifier.warning(`invalid entry selected`)
            }
        });
    }

    if (downloadBtn) {
        downloadBtn.addEventListener('click', (e) => {
            e.preventDefault()

            const val = $timeEntry.select2("val")

            window.open("/dashboard/time-entries/" + val + "/download")
        })
    }
})