document.addEventListener("DOMContentLoaded", () => {
    $("#customer").select2({
        tags: true
    })

    updateProjectRate = (e) => {
        document.getElementById(e.dataset.target).value = e.value * 1.2
        calculateTotals()
    }

    const projectTitle = document.getElementById("project-wo")

    if (projectTitle) {
        document.getElementById('code').addEventListener("keyup", (e) => {
            projectTitle.innerText = e.target.value
        })
    }

    deleteProjectRow = (e) => {
        e.parentNode.parentNode.removeChild(e.parentNode)
        calculateTotals()
    }

    deleteProjectRound = (e) => {
        const parentNode = e.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode
        const length = parentNode.children.length

        parentNode.removeChild(e.parentNode.parentNode.parentNode.parentNode.parentNode)

        if (length > 1) {
            const children = parentNode.children
            let counter = 0

            for (let i = 0; i < children.length; i++) {
                counter++
                children[i].childNodes.item(1).childNodes.item(1).textContent = 'Round ' + counter.toString()
            }

        }
    }

    addProjectRound = (e, index) => {
        const el = document.getElementById(e.dataset.target)
        const len = el.childElementCount + 1
        const row = document.getElementById("projectrounditem").dataset.template.replace(/__S/g, index).replace(/__R/g, len.toString())

        el.insertAdjacentHTML('beforeend', row)
    }

    updateProjectExternalRate = (e) => {
        const externalTarget = document.getElementById(e.dataset.target)
        externalTarget.value = e.value * 1.2

        const roleTargetValue = document.getElementById(e.dataset.role).value
        const items = document.getElementsByClassName("lineitemrole")


        for (let i = 0; i < items.length; i++) {
            if (roleTargetValue === items[i].value) {
                document.getElementById(items[i].dataset.internalrate).value = e.value
            }
        }

        updateProjectExternalLineRate(externalTarget)
    }

    getProjectRateCardRate = (e) => {
        const items = document.getElementsByClassName("ratecardrole")

        for (let i = 0; i < items.length; i++) {
            if (e.value === items[i].value) {
                document.getElementById(e.dataset.internalrate).value = document.getElementById(items[i].dataset.internalrate).value
                document.getElementById(e.dataset.externalrate).value = document.getElementById(items[i].dataset.externalrate).value
                break
            }
        }
        calculateTotals()
    }

    updateProjectExternalLineRate = (e) => {
        const roleTargetValue = document.getElementById(e.dataset.role).value
        const items = document.getElementsByClassName("lineitemrole")

        for (let i = 0; i < items.length; i++) {
            if (roleTargetValue === items[i].value) {
                document.getElementById(items[i].dataset.externalrate).value = e.value
            }
        }
        calculateTotals()
    }

    calculateTotals = () => {
        const workOrderOverview = document.getElementById("workorder-overview")
        const internal = document.getElementsByClassName("projectInternalLineItemRate")
        const external = document.getElementsByClassName("projectExternalLineItemRate")
        const workOrderInternalTotalCost = document.getElementById("workOrderInternalTotalCost")
        const workOrderExternalTotalCost = document.getElementById("workOrderExternalTotalCost")
        const workOrderTotalProfit = document.getElementById("workOrderTotalProfit")
        const workOrderTotalProfitPercentage = document.getElementById("workOrderTotalProfitPercentage")

        let internalTotal = 0
        let externalTotal = 0

        for (let i = 0; i < internal.length; i++) {
            const roundsTargetClassName = internal[i].dataset.roundstarget
            const rounds = document.getElementsByClassName(roundsTargetClassName)
            let totalRounds = 0

            for (let i = 0; i < rounds.length; i++) {
                totalRounds = parseFloat(rounds[i].value)
            }

            internalTotal += parseFloat(internal[i].value) * totalRounds
        }

        for (let i = 0; i < external.length; i++) {
            const roundsTargetClassName = external[i].dataset.roundstarget
            const rounds = document.getElementsByClassName(roundsTargetClassName)
            let totalRounds = 0

            for (let i = 0; i < rounds.length; i++) {
                totalRounds = parseFloat(rounds[i].value)
            }

            externalTotal += parseFloat(external[i].value) * totalRounds
        }

        const totalProfit = externalTotal - internalTotal
        const totalPercent = (totalProfit / internalTotal) * 100

        const pounds = Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: 'GBP',
        });

        workOrderInternalTotalCost.innerText = pounds.format(internalTotal)
        workOrderExternalTotalCost.innerText = pounds.format(externalTotal)
        workOrderTotalProfit.innerText = pounds.format(totalProfit)
        workOrderTotalProfitPercentage.innerText = "%" + totalPercent.toFixed(2)

        let totalRowClass = "table-success"
        if (totalPercent >= 15 && totalPercent < 20) {
            totalRowClass = "table-warning"
        }

        if (totalPercent < 15) {
            totalRowClass = "table-danger"
        }

        workOrderOverview.className = totalRowClass
    }

    const addProjectItems = document.getElementById("addprojectitems")

    if (addProjectItems) {
        const projectItems = document.getElementById("projectitems")
        let count = projectItems.childElementCount

        addProjectItems.addEventListener("click", () => {
            const row = addProjectItems.dataset.template.replace(/__S/g, count.toString())

            projectItems.insertAdjacentHTML("beforeend", row)

            count++
        })

        calculateTotals()
    }
})