document.addEventListener("DOMContentLoaded", () => {
    updateRate = (e) => {
        document.getElementById(e.dataset.target).value = e.value * 1.2
    }

    deleteRateLineRow = (e) => {
        e.parentNode.remove()
    }

    const picker = document.getElementById("start_date")

    if (picker) {
        new easepick.create({
            element: picker,
            format: "DD-MM-YYYY",
            css: [
                'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.css',
                'https://cdn.jsdelivr.net/npm/@easepick/lock-plugin@1.2.1/dist/index.css',
            ],
            zIndex: 10,
            plugins: [
                "RangePlugin",
                "LockPlugin"
            ],
            RangePlugin: {
                elementEnd: document.getElementById('end_date'),
            },
            LockPlugin: {
                minDate: new Date(),
            },
            calendars: 4,
            grid: 2,
        })
    }

    const addRateCardItem = document.getElementById("addrateitem")

    if (addRateCardItem) {
        const ratesContainer = document.getElementById("rates")

        let count = ratesContainer.childElementCount

        addRateCardItem.addEventListener("click", () => {
            const template = addRateCardItem.dataset.template.replace(/__S/g, count.toString())

            ratesContainer.insertAdjacentHTML("beforeend", template)

            count++
        })
    }
})