import AWN from "awesome-notifications"

document.addEventListener("DOMContentLoaded", () => {
    $('.generic-select2').select2({})

    const reloadItems = document.getElementsByClassName("reload-page")

    if (reloadItems) {
        for (let i = 0; i < reloadItems.length; i++) {
            reloadItems[i].addEventListener('click', (e) => {
                e.preventDefault()

                let notifier = new AWN()

                notifier.asyncBlock(
                    axios.get(e.currentTarget.dataset.url).then(() => {
                        window.location.reload()
                    }).catch(err => {
                        notifier.alert(err.message)
                    }),
                    null,
                )
            })
        }
    }
})