document.addEventListener("DOMContentLoaded", () => {
    const picker = document.getElementById("range_time_entries_start_date")

    if (picker) {
        new easepick.create({
            element: picker,
            format: "DD-MM-YYYY",
            css: [
                'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.css',
                'https://cdn.jsdelivr.net/npm/@easepick/lock-plugin@1.2.1/dist/index.css',
            ],
            zIndex: 10,
            plugins: [
                "LockPlugin"
            ],
            LockPlugin: {
                maxDate: new Date(),
            },
            calendars: 4,
            grid: 2,
            setup(picker) {
                picker.on('select', (e) => {
                    document.getElementById("range_time_entries").submit()
                })
            }
        })
    }
})